import { ReactNode } from 'react';

import { MoonIcon, SunIcon } from 'lucide-react';
import { Theme, useTheme } from 'remix-themes';

import { Button } from './ui/button';

type SafeTheme = [
  ReturnType<typeof useTheme>[0],
  ReturnType<typeof useTheme>[1],
  ReturnType<typeof useTheme>[2],
  // is the theme value trusted?
  boolean,
];

const NOOP_THEME: SafeTheme = [
  null,
  () => {},
  { definedBy: 'USER' },
  false,
] as const;

export function useSafeTheme(): SafeTheme {
  try {
    return [...useTheme(), true];
  } catch {
    return NOOP_THEME;
  }
}

export function ThemeToggle() {
  const [theme, setTheme, , isTrusted] = useSafeTheme();

  if (!isTrusted) {
    return null;
  }

  let otherTheme: Theme;
  let otherThemeIcon: ReactNode;
  if (theme === Theme.LIGHT) {
    otherTheme = Theme.DARK;
    otherThemeIcon = (
      <MoonIcon className="stroke-muted-foreground group-hover:stroke-primary group-focus:stroke-primary h-[1rem] w-[1rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
    );
  } else {
    otherTheme = Theme.LIGHT;
    otherThemeIcon = (
      <SunIcon className="stroke-muted-foreground group-hover:stroke-primary group-focus:stroke-primary h-[1rem] w-[1rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
    );
  }

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 group"
      size="icon"
      aria-label="Toggle theme"
      onClick={() => {
        setTheme(otherTheme);
      }}
    >
      {otherThemeIcon}
    </Button>
  );
}
