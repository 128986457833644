import {
  SignIn as ClerkSignIn,
  SignUp as ClerkSignUp,
  UserButton as ClerkUserButton,
} from '@clerk/remix';
import { dark as clerkDarkTheme } from '@clerk/themes';
import { BellIcon } from 'lucide-react';
import { Theme } from 'remix-themes';

import { settingsNotificationsRoute } from '@matchastock/routes';

import { useSafeTheme } from './theme-toggle';

const useClerkTheme = () => {
  const [theme] = useSafeTheme();

  if (theme === Theme.DARK) {
    return clerkDarkTheme;
  }
  return undefined;
};

export const UserButton = () => {
  const theme = useClerkTheme();
  return (
    <ClerkUserButton
      showName
      appearance={{
        baseTheme: theme,
        elements: {
          userButtonOuterIdentifier: 'text-primary text-xs sm:text-sm',
        },
      }}
      userProfileMode="modal"
      userProfileProps={{
        appearance: {
          baseTheme: theme,
        },
      }}
    >
      <ClerkUserButton.MenuItems>
        <ClerkUserButton.Link
          label="Notifications"
          labelIcon={<BellIcon size="1rem" strokeWidth="2.5px" />}
          href={settingsNotificationsRoute()}
        />
      </ClerkUserButton.MenuItems>
    </ClerkUserButton>
  );
};

export const SignIn = () => {
  const theme = useClerkTheme();

  return (
    <ClerkSignIn
      appearance={{
        baseTheme: theme,
      }}
    />
  );
};

export const SignUp = () => {
  const theme = useClerkTheme();

  return (
    <ClerkSignUp
      appearance={{
        baseTheme: theme,
      }}
    />
  );
};
